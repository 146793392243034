import * as React from 'react';
import T from '../components/T';

export interface PricingFaqItemProps {
  q: React.ReactElement;
  a: React.ReactElement;
}

export const pricingFaqData: PricingFaqItemProps[] = [
  {
    q: <T id="What is the difference between the Free and PRO version?" />,
    a: (
      <T id="Our Free version is limited to 10 participants. You need to upgrade to PRO with an audience above 10. All sessions (free or PRO) have access to all features." />
    ),
  },
  {
    q: (
      <T id="Is there a limit to the size of file I can convert on Hypersay?" />
    ),
    a: (
      <T id="No, there is no limit to the size but the larger the file, the more time it takes to convert. Patience is required! :)" />
    ),
  },
  {
    q: <T id="How many people can log into my Hypersay session?" />,
    a: (
      <T id="Our free version limits you to 10 participants. Above that number you need to upgrade the session to PRO in order to allow others to join. See our Hypersay pricing plans. It is simple and inexpensive." />
    ),
  },
  {
    q: (
      <T id="What happens if I reach the maximum number of 10 participants during a live Hypersay session? Do new joiners get blocked?" />
    ),
    a: (
      <T id="Yes. The participant will receive a notification that the session is full and they are waiting for access. The presenter will receive a notification that allows them to quickly upgrade the session to PRO. For time constraints we also added a option to 'upgrade now, pay later'." />
    ),
  },
  {
    q: <T id="How can I pay for Hypersay?" />,
    a: (
      <T id="You can pay by Credit Card on our website. Accepted cards: Visa, Mastercard, American Express credit and debit cards. We will soon add Apple Pay and Google Pay." />
    ),
  },
  {
    q: (
      <T id="What is the maximum capacity of attendees in a Hypersay session?" />
    ),
    a: (
      <T id=" Our maximum capacity for PRO is 200 attendees. We can support up to 2000 attendees, but our pricing is different with larger audiences because we offer a different support model on the day of your Event. We charge $50 per day for up to 500 participants with 3 different sessions on that day. We charge $100 for +500 participants with 3 different sessions on that day. Contact us if you wish to speak to us about the pricing and services for a large event." />
    ),
  },
  {
    q: <T id="Where do you store my credit card information?" />,
    a: (
      <T id="We do not store your credit card information on our servers. We use Stripe to authenticate and secure credit card transactions. Stripe is using industry-leading encryption to ensure the security of your credit card information." />
    ),
  },
  {
    q: <T id="What if I change my mind?" />,
    a: (
      <T id="If you wish to cancel within fourteen (14) days from the day you purchased and you have not used Hypersay, you will receive a full refund." />
    ),
  },
  {
    q: <T id="Do you have preferential pricing for education?" />,
    a: (
      <T id="No, we offer large discounted pricing to all of our users. We do offer discounts if you wish to purchase a group of licenses for a company or a school." />
    ),
  },
  {
    q: <T id="How long is the duration of a Hypersay session?" />,
    a: (
      <T id="Once you create a session it will stay inside your dashboard until you request us to delete it or you delete your account. Live session are not limited in time, but on average our presenters use Hypersay for 1-2h." />
    ),
  },
  {
    q: <T id="Can I terminate my monthly subscription?" />,
    a: (
      <T id="Yes. Please contact us and we will delete your monthly subscription on the day it is to be renewed." />
    ),
  },
];

export default pricingFaqData;
