import React, { Component, ReactNode } from 'react';

import { Button, Stack } from '@hypersay/ui';
import { Tab, TabList, TabPanel, useTabState } from 'reakit';

import navigateToAppSite from '../util/navigate-to-app-site';

import EmailContactModal from '../components/emailContactModal';
import SEO from '../components/seo';
import T from '../components/T';
import {
  pricingFaqData,
  PricingFaqItemProps,
} from '../data/pricingFaqQuestionsData';
import Layout from '../layouts/default';

import { Link } from '../components/Link';
import LoginModal from '../components/LoginModal';
import * as styles from './pricing.module.scss';
import { withPrefix } from 'gatsby';

const faqRender = (data: PricingFaqItemProps[]) => (
  <div className={styles.faqGridContainer}>
    <T id="Frequently Asked Questions" tagName="h1" />
    <div className={styles.faqGrid}>
      {data.map(({ q, a }: PricingFaqItemProps) => (
        <div key={JSON.stringify(q)}>
          <h4>{q}</h4>
          <p>{a}</p>
        </div>
      ))}
    </div>
  </div>
);

const redirectToPayment = ({
  step,
  type,
  preselectedProduct,
  redirect = window.location.href,
}: {
  step: number;
  type: string;
  preselectedProduct?: string;
  redirect?: string;
}) => {
  if (window) {
    const query = {
      step,
      type,
      preselectedProduct,
      redirect,
    };
    const queryString = Object.entries(query)
      .filter(([_, v]) => !!v)
      .map(
        ([k, v]) =>
          `${encodeURIComponent(k)}=${encodeURIComponent(
            v as string | number,
          )}`,
      )
      .join('&');
    navigateToAppSite(`payment?${queryString}`);
  }
};

const showRegularCreditBuy = () =>
  redirectToPayment({ step: 2, type: 'credits' });
const showRegularSubscribe = (product?: string) =>
  redirectToPayment({
    step: 2,
    type: 'subscription',
    preselectedProduct: product,
  });

interface ProFeaturesProps {
  nrParticipants: ReactNode;
  nrProSessions: ReactNode;
}

const ProFeatures = ({ nrParticipants, nrProSessions }: ProFeaturesProps) => (
  <Stack
    direction="vertical"
    style={{ alignItems: 'center', marginTop: '1.5em', marginBottom: '1.5em' }}
    gap="1.5em"
  >
    <T
      id="Max. participants / session: {nr}"
      values={{
        nr: <b style={{ color: 'var(--urobilin)' }}>{nrParticipants}</b>,
      }}
    />
    <T
      id="Pro sessions: {nr}"
      values={{
        nr: <b style={{ color: 'var(--urobilin)' }}>{nrProSessions}</b>,
      }}
    />
    <div>
      <Stack direction="vertical" style={{ alignItems: 'center' }} gap="3px">
        <T tagName="span" id="Unlimited interactions" />
        <T tagName="span" id="Session Analytics" />
        <T tagName="span" id="Advanced session control" />
        <Link to="/live-presentations" className={styles.link}>
          <T tagName="span" id="See all features" />
        </Link>
      </Stack>
    </div>
  </Stack>
);

const PricingFreePresentation = () => (
  <div className={`${styles.pricingBox} ${styles.free}`}>
    <T tagName="h1" id="Free" />
    <div className={styles.actualPricing}>
      <div className={styles.actualPricingContainer}>
        <span className={styles.dollars}>$0</span>
        <T id="per presentation" />
        <Stack
          direction="vertical"
          style={{
            alignItems: 'center',
            marginTop: '1.5em',
            marginBottom: '1.5em',
          }}
          gap="1.5em"
        >
          <T
            id="Max. participants / session: {nr}"
            values={{
              nr: <b style={{ color: 'var(--urobilin)' }}>10</b>,
            }}
          />
          <div style={{ marginTop: '6em' }}>
            <Stack
              direction="vertical"
              style={{ alignItems: 'center' }}
              gap="3px"
            >
              <T tagName="span" id="Unlimited interactions" />
              <T tagName="span" id="Session Analytics" />
              <T tagName="span" id="Advanced session control" />
              <Link to="/live-presentations" className={styles.link}>
                <T tagName="span" id="See all features" />
              </Link>
            </Stack>
          </div>
        </Stack>
        <LoginModal
          onLoggedIn={navigateToAppSite}
          trigger={
            <Button
              label={<T id="Get started now" />}
              colors={['--splashed-white', '--payne-grey']}
              size="m"
            />
          }
        />
      </div>
    </div>
  </div>
);

const PricingSinglePresentation = () => (
  <div className={`${styles.pricingBox} ${styles.single}`}>
    <T tagName="h1" id="Single presentation" />
    <div className={styles.actualPricing}>
      <div className={styles.actualPricingContainer}>
        <span className={styles.dollars}>
          $3<sup style={{ fontSize: '.6em' }}>.99</sup>
        </span>
        <T id="per presentation" />
        <ProFeatures nrParticipants="10-200" nrProSessions="1" />
        <LoginModal
          onLoggedIn={showRegularCreditBuy}
          trigger={
            <Button
              label={<T id="Get started now" />}
              colors={['--splashed-white', '--payne-grey']}
              size="m"
            />
          }
        />
      </div>
    </div>
  </div>
);

const PricingIndividualPlan = () => {
  const tab = useTabState({
    selectedId: 'annually',
  });

  return (
    <div className={`${styles.pricingBox} ${styles.plan}`}>
      <T tagName="h1" id="Individual plan" />
      <div className={styles.actualPricing}>
        <>
          <TabList
            {...tab}
            aria-label="Annually or monthly toggle"
            className={styles.hsPricingChoices}
          >
            <Tab {...tab} as="span" stopId="annually" className={styles.tab}>
              <Button
                size="s"
                variant={tab.selectedId !== 'annually' ? 'default' : 'primary'}
                label={<T id="annually" />}
                className={styles.tabLeft}
              />
            </Tab>
            <Tab {...tab} as="span" stopId="monthly" className={styles.tab}>
              <Button
                size="s"
                variant={tab.selectedId !== 'monthly' ? 'default' : 'primary'}
                label={<T id="monthly" />}
                className={styles.tabRight}
              />
            </Tab>
          </TabList>
          <TabPanel
            {...tab}
            stopId="annually"
            className={styles.actualPricingContainer}
          >
            <span className={styles.dollars}>
              $9<sup style={{ fontSize: '.6em' }}>.99</sup>
            </span>
            <T id="per month, billed annually" />
            <ProFeatures
              nrParticipants="10-200"
              nrProSessions={<T id="unlimited" />}
            />
            <LoginModal
              trigger={
                <Button
                  variant="primary"
                  size="m"
                  label={<T id="Subscribe now" />}
                  theme="dark"
                />
              }
              onLoggedIn={() =>
                showRegularSubscribe(
                  process.env.HYPERSAY_SUBSCRIPTION_ANNUALLY_ID,
                )
              }
            />
          </TabPanel>
          <TabPanel
            {...tab}
            stopId="monthly"
            className={styles.actualPricingContainer}
          >
            <span className={styles.dollars}>
              $14<sup style={{ fontSize: '.6em' }}>.99</sup>
            </span>
            <T id="per month, pay-as-you-go" />
            <ProFeatures
              nrParticipants="10-200"
              nrProSessions={<T id="unlimited" />}
            />
            <LoginModal
              trigger={
                <Button
                  variant="primary"
                  size="m"
                  label={<T id="Subscribe now" />}
                  theme="dark"
                />
              }
              onLoggedIn={() =>
                showRegularSubscribe(
                  process.env.HYPERSAY_SUBSCRIPTION_MONTHLY_ID,
                )
              }
            />
          </TabPanel>
        </>
      </div>
    </div>
  );
};

const PricingEvents = () => {
  // const tab = useTabState({
  //   selectedId: '500',
  // });

  return (
    <div className={`${styles.pricingBox} ${styles.events}`}>
      <T tagName="h1" id="Events" />
      <div className={styles.eventPricingBox}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <a
            href="https://hypersay.events/?utm_source=hypersaycom"
            style={{ textDecoration: 'none' }}
            target="_blank"
          >
            <img
              src={withPrefix('/logos/logo-hse-vertical-positive.svg')}
              alt="hypersay events logo"
            />
          </a>
          <div style={{ textAlign: 'center', marginTop: '1.2em' }}>
            <T id="For running live virtual events we have created a separate product, Hypersay Events." />
          </div>
          <div style={{ textAlign: 'center', marginTop: '1em' }}>
            <T id="Live streaming, video studio, state-of-the-art networking features, chat and much more." />
          </div>
        </div>
        <a
          href="https://hypersay.events/?utm_source=hypersaycom"
          style={{ textDecoration: 'none' }}
          target="_blank"
        >
          <Button
            label={<T id="Go to Hypersay.events" />}
            variant="default"
            colors={['--splashed-white', '--valencia']}
            size="m"
            theme="dark"
          />
        </a>
      </div>
      {/* <div className={styles.actualPricing}>
        <>
          <TabList
            {...tab}
            aria-label="500 or 500+ event toggle"
            className={styles.hsPricingChoices}
          >
            <Tab {...tab} stopId="500" as="span" className={styles.tab}>
              <Button
                variant={tab.selectedId !== '500' ? 'default' : 'primary'}
                // theme="dark"
                label="up to 500"
                rounded="0px"
                className={styles.tabLeft}
              />
            </Tab>
            <Tab {...tab} stopId="500+" as="span" className={styles.tab}>
              <Button
                variant={tab.selectedId !== '500+' ? 'default' : 'primary'}
                // theme="dark"
                label="500+"
                rounded="0px"
                className={styles.tabRight}
              />
            </Tab>
          </TabList>
          <TabPanel
            {...tab}
            stopId="500"
            className={styles.actualPricingContainer}
          >
            <span className={styles.dollars}>$50</span>
            <T id="per day, up to 500 participants" />
            <ProFeatures nrParticipants="10-500" nrProSessions={3} />
            <a
              href="mailto:hello@hypersay.com?cc=brian.daly@hypersay.com&subject=Hypersay - events"
              className={styles.nolink}
              rel="noopener noreferrer"
            >
              <Button
                variant="default"
                outline={true}
                size="m"
                theme="dark"
                label={
                  <T id="Contact us" />
                }
              />
            </a>
          </TabPanel>
          <TabPanel
            {...tab}
            stopId="500+"
            className={styles.actualPricingContainer}
          >
            <span className={styles.dollars}>$100</span>
            <T id="per day, 500+ participants" />
            <ProFeatures nrParticipants="500+" nrProSessions={3} />
            <a
              href="mailto:hello@hypersay.com?cc=brian.daly@hypersay.com&subject=Hypersay - large events"
              className={styles.nolink}
              rel="noopener noreferrer"
            >
              <Button
                variant="default"
                theme="dark"
                outline={true}
                size="m"
                label={
                  <T id="Contact us" />
                }
              />
            </a>
          </TabPanel>
        </>
      </div> */}
    </div>
  );
};

class Pricing extends Component {
  public state = {
    modalShown: false,
    modalAction: '',
  };

  public showModal = (template: string) => () => {
    this.setState({
      modalShown: true,
      modalAction: template,
    });
  };

  public hideModal = () => {
    this.setState({
      modalShown: false,
      modalAction: '',
    });
  };

  public render() {
    const { modalShown, modalAction } = this.state;
    return (
      <Layout>
        <SEO
          title="Pricing - Hypersay"
          description="Start with interactive presentations for free, for up to 10 participants."
          keywords={[
            'live presentation',
            'interactive presentation',
            'live polling',
            'live poll',
            'session analytics',
            'digital pulse',
            'ppt',
            'powerpoint',
            'slides',
            'google slides',
            'alternative to glisser',
            'alternative to polleverywhere',
            'alternative to beekast',
            'alternative to prezi',
          ]}
        />
        <div className={styles.hsPricingContainer}>
          <header>
            <T tagName="h1" id="Pricing" />
            <T
              tagName="h2"
              id={
                // tslint:disable-next-line: max-line-length
                'Pay for what you need. Start with free session of up to 10 participants. For more, upgrade a single session or purchase a subscription. Having an event? Choose the right package.'
              }
            />
            {/* <Stack direction="horizontal" className={styles.pricingBoxSmall}>
              <T id="Free — up to 10 participants" />
              <LoginModal
                trigger={
                  <Button
                    label={<T id="Get started now" />}
                    colors={['--splashed-white', '--payne-grey']}
                    size="s"
                    className={styles.smallBoxBtn}
                  />
                }
              />
            </Stack> */}
          </header>
          <div className={styles.pricingGridContainer}>
            <div className={styles.pricingGrid}>
              <PricingFreePresentation />
              <PricingSinglePresentation />
              <PricingIndividualPlan />
              <PricingEvents />
            </div>
          </div>
          <Stack direction="horizontal" className={styles.pricingBoxSmall}>
            <T id="Enterprise — custom integrations, SSO, security" />
            <a
              href="mailto:hello@hypersay.com?cc=brian.daly@hypersay.com&subject=Hypersay - enterprise"
              className={styles.nolink}
              rel="noopener noreferrer"
            >
              <Button
                label={<T id="Contact us" />}
                variant="default"
                outline={true}
                size="s"
                className={styles.smallBoxBtn}

                /* onClick={this.showModal('ENTERPRISE_VERSION')} */
              />
            </a>
          </Stack>
          {/* <StaticQuery query={faqQuery} render={faqRender} /> */}
          {faqRender(pricingFaqData)}

          <EmailContactModal
            visible={modalShown}
            hide={this.hideModal}
            action={modalAction}
          />
        </div>
      </Layout>
    );
  }
}
export default Pricing;
