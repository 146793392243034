import React, { useState } from 'react';
import { Box, Button } from 'reakit';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';
import T from '../components/T';
import styles from './emailContactModal.scss';

const EmailContactModal = (
  props: { visible: boolean; hide: () => void; action: string } = {
    visible: false,
    action: '',
    hide: () => undefined,
  },
) => {
  const [email, setEmail] = useState('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const hide = () => {
    setEmail('');
    props.hide();
  };

  const sendEmailToCustomerService = (action: string) => {
    fetch(`${process.env.HYPERSAY_GATEWAY_API}/email`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: action,
    });
  };

  const sendAndHide = (e: React.FormEvent<HTMLFormElement>) => {
    const { action } = props;
    e.preventDefault();
    if (email) {
      hide();
      sendEmailToCustomerService(action);
    }
  };

  const dialog = useDialogState();
  const { visible } = props;

  return (
    <div>
      <DialogBackdrop {...dialog} visible={visible} onClick={hide} />
      <Dialog
        {...dialog}
        visible={visible}
        aria-label="Your Email"
        className={styles.container}
      >
        <h1>
          <T id="Your email" />
        </h1>
        <p>
          <T id="Please leave your email and we will contact you." />
        </p>
        <Box as="form" className="form" onSubmit={sendAndHide}>
          <input
            onChange={onChange}
            placeholder={'you@example.com'}
            type="email"
            name="email" // just to help the autocompleter
            size={20}
            value={email}
            required={true}
          />
          <Button type="submit">
            <span>
              <T id="Ok" />
            </span>
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default EmailContactModal;
